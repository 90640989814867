import { phoneNumberRegex } from "@utils/phoneNumberValidation";
import z from "zod";

export const SEARCH_RADIUS_OPTIONS = ["2", "4", "8", "16"] as const;

export const DEFAULT_SEARCH_RADIUS = SEARCH_RADIUS_OPTIONS[2];

export type SearchRadiusOptionsTypes = (typeof SEARCH_RADIUS_OPTIONS)[number];

export const getOffersPageFormSchema = (isZipCodeRequired: boolean) => {
  return z.object({
    phoneNumber: z
      .string()
      .regex(phoneNumberRegex, "This phone number is not valid"),
    zipCode: isZipCodeRequired
      ? z.string().regex(/^[0-9]+$/, "Please enter a valid ZIP code")
      : z
          .string()
          .regex(/^[0-9]+$/, "Please enter a valid ZIP code")
          .optional(),
    isPartnersOpted: z.literal<boolean>(true),
    isAisleOpted: z.literal<boolean>(true),
  });
};

export type OffersPageFormSchemaInterface = z.infer<
  ReturnType<typeof getOffersPageFormSchema>
>;

export type PhoneFormSchemaInterface = z.infer<typeof PhoneInputSchema>;
export type ZipCodeFormSchemaInterface = z.infer<typeof ZipCodeInputSchema>;

export type OffersFilterFormSchemaInterface = z.infer<
  typeof OffersFilterSchema
>;

export const OffersQuerySchema = z.object({
  zipCode: z
    .string()
    .regex(/^[0-9]+$/, "Please enter a valid ZIP code")
    .optional(),
  latitude: z.string().optional(),
  longitude: z.string().optional(),
  customerPhoneNumber: z
    .string()
    .regex(phoneNumberRegex, "This phone number is not valid")
    .optional(),
  searchRadiusInMiles: z
    .enum(SEARCH_RADIUS_OPTIONS)
    .optional()
    .default(DEFAULT_SEARCH_RADIUS),
});

export const OffersFilterSchema = z.object({
  zipCode: z
    .string()
    .min(5, "ZIP Code must be 5 digits")
    .max(5, "ZIP Code must be 5 digits")
    .regex(/^[0-9]+$/, "Please enter a valid ZIP code")
    .optional(),
  merchants: z.string().array().optional(),
  range: z
    .enum(SEARCH_RADIUS_OPTIONS)
    .optional()
    .default(DEFAULT_SEARCH_RADIUS),
  showRedeemed: z.literal<boolean>(false),
});

export const PhoneInputSchema = z.object({
  phoneNumber: z
    .string()
    .regex(phoneNumberRegex, "This phone number is not valid"),
  isAisleOpted: z.literal<boolean>(true),
});

export const ZipCodeInputSchema = z.object({
  zipCode: z
    .string()
    .regex(/^\d{5}(?:[-\s]\d{4})?$/, "Please enter a valid ZIP code")
    .optional(),
});

export type OffersQuerySchemaInterface = z.infer<typeof OffersQuerySchema>;

export const ZipCodeSelectSchema = z.object({
  zipCode: z.string().regex(/^[0-9]+$/, "Please enter a valid ZIP code"),
});

export type ZipCodeSelectSchemaInterface = z.infer<typeof ZipCodeSelectSchema>;

export const ZipCodeAndRadiusSelectSchema = z.object({
  zipCode: z.string().regex(/^[0-9]+$/, "Please enter a valid ZIP code"),
  searchRadiusInMiles: z.enum(SEARCH_RADIUS_OPTIONS),
});

export type ZipCodeAndRadiusSelectSchemaInterface = z.infer<
  typeof ZipCodeAndRadiusSelectSchema
>;

export const OptInSchema = z.object({
  hasAcceptedTermsAndConditions: z.literal<boolean>(true),
});

export type OptInSchemaInterface = z.infer<typeof OptInSchema>;

export const VerifyAgeSchema = z
  .object({
    month: z
      .number()
      .int()
      .min(1, "This is an invalid month")
      .max(12, "This is an invalid month"),
    day: z
      .number()
      .int()
      .min(1, "This is an invalid day")
      .max(31, "This is an invalid day"),
    year: z
      .number()
      .int()
      .min(1900, "This is an invalid year")
      .max(new Date().getFullYear(), "This is an invalid year"),
    invalidAge: z.never().optional(),
  })
  .refine(
    (schema) => {
      const date = new Date(schema.year, schema.month - 1, schema.day);
      const validDate = new Date(
        new Date().setFullYear(new Date().getFullYear() - 21)
      );
      return date <= validDate;
    },
    {
      message:
        "You are not the legal age to access our alcoholic offers. Sorry about that!",
      path: ["invalidAge"],
    }
  );
