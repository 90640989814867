import * as React from "react";

import { cn } from "utils/cn";

export type InputWithIconProps = React.InputHTMLAttributes<HTMLInputElement> & {
  decorator?: React.ReactNode;
};

const InputWithIcon = React.forwardRef<HTMLInputElement, InputWithIconProps>(
  ({ className, type, decorator, ...props }, ref) => {
    return (
      <div
        className={cn(
          "shadow-input ",
          "placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50 rounded-md",
          "flex w-full",
          className
        )}
      >
        <span
          className={cn(
            "inline-flex items-center",
            "border-b border-l border-t border-slate-300 ",
            "bg-slate-50",
            "rounded-bl-md rounded-tl-md",
            "pl-3.5 pr-3"
          )}
        >
          {decorator}
        </span>
        <input
          type={type}
          className={cn(
            "w-full px-3 py-3",
            "border border-slate-300",
            "rounded-br-md rounded-tr-md",
            "focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring",
            "text-base font-normal leading-snug text-black",
            "h-12",
            className
          )}
          ref={ref}
          {...props}
        />
      </div>
    );
  }
);
InputWithIcon.displayName = "InputWithIcon";

export { InputWithIcon };
