import {
  DEFAULT_SEARCH_RADIUS,
  SearchRadiusOptionsTypes,
} from "validations/offerspage.validation";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { createSelectors } from "./helpers/createSelectors";
import { SearchMethod } from "@lib/offers";

type UIState = {
  referrerUrl: string;
  customerId: string;
  phoneNumber: string;
  zipCode: string;
  searchRadiusInMiles: SearchRadiusOptionsTypes;
  previouslySearchedZipCodes: string[];
  isBrowserGeolocationAccepted: boolean;
  browserLongitude: number | undefined;
  browserLatitude: number | undefined;
  searchMethod: SearchMethod;
};

type UIActions = {
  setReferrer: (referrerUrl: string) => void;
  setCustomerId: (customerId: string) => void;
  setPhoneNumber: (phoneNumber: string) => void;
  setZipCode: (zipCode: string) => void;
  setSearchRadius: (radius: SearchRadiusOptionsTypes) => void;
  setPreviouslySearchedZipCodes: (radius: string[]) => void;
  setBrowserGeolocationAccepted: (isAccepted: boolean) => void;
  setSearchMethod: (method: SearchMethod) => void;
  setBrowserLocation: (
    longitude: number | undefined,
    latitude: number | undefined
  ) => void;
};

const useCustomerStoreBase = create(
  immer<UIState & UIActions>((set) => ({
    referrerUrl: "",
    customerId: "",
    phoneNumber: "",
    zipCode: "",
    searchRadiusInMiles: DEFAULT_SEARCH_RADIUS,
    previouslySearchedZipCodes: [],
    isBrowserGeolocationAccepted: false,
    browserLatitude: undefined,
    browserLongitude: undefined,
    searchMethod: "zipcode",
    setReferrer: (referrerUrl: string) =>
      set((state) => {
        state.referrerUrl = referrerUrl;
      }),
    setCustomerId: (customerId: string) =>
      set((state) => {
        state.customerId = customerId;
      }),
    setPhoneNumber: (phoneNumber: string) =>
      set((state) => {
        state.phoneNumber = phoneNumber;
      }),
    setZipCode: (zipCode: string) =>
      set((state) => {
        state.zipCode = zipCode;
      }),
    setSearchRadius: (searchRadiusInMiles: SearchRadiusOptionsTypes) =>
      set((state) => {
        state.searchRadiusInMiles = searchRadiusInMiles;
      }),

    setPreviouslySearchedZipCodes: (zipCodes: string[]) =>
      set((state) => {
        state.previouslySearchedZipCodes = zipCodes;
      }),
    setBrowserGeolocationAccepted: (isAccepted: boolean) =>
      set((state) => {
        state.isBrowserGeolocationAccepted = isAccepted;
      }),
    setSearchMethod: (method: SearchMethod) =>
      set((state) => {
        state.searchMethod = method;
      }),
    setBrowserLocation: (
      longitude: number | undefined,
      latitude: number | undefined
    ) =>
      set((state) => {
        state.browserLongitude = longitude;
        state.browserLatitude = latitude;
      }),
  }))
);

export const useCustomerStore = createSelectors(useCustomerStoreBase);