import * as lucideIcons from "lucide-react";
import { twMerge } from "tailwind-merge";

export const { icons } = lucideIcons;

export interface LucideProps extends React.ComponentPropsWithoutRef<"svg"> {
  icon: keyof typeof icons;
  title?: string;
}

export function Lucide(props: LucideProps) {
  const { icon, className, ...computedProps } = props;
  const Component = icons[icon] ?? undefined;
  if (!Component) return null;
  return <Component {...computedProps} className={twMerge(["h-6 w-6 stroke-[1]", className])} />;
}
