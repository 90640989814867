import { VariantProps, cva } from "class-variance-authority";
import * as React from "react";

import { cn } from "utils/cn";

const inputVariants = cva(
  "text-base font-normal leading-snug inline-flex w-full items-start justify-start gap-2.5 px-3 py-3 rounded-md border shadow-input placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 h-12",
  {
    variants: {
      variant: {
        default: "text-black border-slate-300 ",
        error: "text-red border-red-500",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, variant, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(inputVariants({ variant }), className)}
        ref={ref}
        {...props}
      />
    );
  }
);
Input.displayName = "Input";

export { Input };
