export type EmptyStateProps = {
  title?: string;
  description?: string;
  children?: React.ReactNode;
};

export const EmptyState = ({ title, description, children }: EmptyStateProps) => {
  return (
    <div className="full flex flex-col items-center">
      <div className="text-center text-xl font-bold leading-[30px] text-slate-800">{title}</div>
      <div className="max-w-[365px] pt-2 text-center text-xs font-medium leading-[18px] text-black text-opacity-50">
        {description}
      </div>
      {children}
    </div>
  );
};

// className={cn(alertBannerVariants({ variant, className }))}
