export const GA_TRACKING_ID = "G-B8VPZ99RZ7"; // Our GTM tracking ID
export const AISLE_TRACKING_ID = "G-2Q4H08NXKH"; // Modern Landing Page tracking id

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string, newTrackingId?: string) => {
  //@ts-expect-error grabbing the global gtag function
  window.gtag("config", newTrackingId ?? GA_TRACKING_ID, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
//@ts-expect-error Not sure of the types yet
export const event = ({ action, category, label, value = undefined }) => {
  //@ts-expect-error grabbing the global gtag function
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};

export const select_content = ({ type = "", id = "" }) => {
  //@ts-expect-error grabbing the global gtag function
  window.gtag("event", "select_content", {
    content_type: type,
    content_id: id,
  });
};

export const share = ({
  method,
  content_type,
  item_id,
}: {
  method: string;
  content_type: string;
  item_id: string;
}) => {
  //@ts-expect-error grabbing the global gtag function
  window.gtag("event", "share", {
    method,
    content_type,
    item_id,
  });
};
