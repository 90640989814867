import { Button } from "@components/common";
import { cva, type VariantProps } from "class-variance-authority";
import { IconType } from "react-icons";
import { BsExclamation } from "react-icons/bs";
import { cn } from "utils/cn";

const alertBannerVariants = cva("flex w-full space-x-4 px-5 pb-6 pt-5", {
  variants: {
    variant: {
      default: "border-b border-slate-200 bg-slate-50",
      warning: "border-b border-amber-200 bg-amber-50",
      destructive: "border-b border-red-200 bg-red-50",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

const alertBannerButtonVariant = cva("border-2", {
  variants: {
    variant: {
      default: "border-slate-500 text-slate-500 hover:text-slate-600",
      warning: "border-amber-500 text-amber-500 hover:text-amber-600",
      destructive: "border-red-500 text-red-500 hover:text-red-600",
    },
  },
});

const alertIconVariant = cva("relative mt-1 flex h-5 w-5 items-center justify-center rounded-full", {
  variants: {
    variant: {
      default: "bg-slate-500",
      warning: "bg-amber-500",
      destructive: "bg-red-500",
    },
  },
});

const iconDictionary: { [key: string]: IconType } = {
  destructive: BsExclamation,
  warning: BsExclamation,
  default: BsExclamation,
};

export interface AlertBannerProps
  extends React.ButtonHTMLAttributes<HTMLDivElement>,
    VariantProps<typeof alertBannerVariants> {
  title?: string;
  description?: string;
  button?: {
    label?: string;
    onClick?: () => void;
  };
}

export const AlertBanner = ({ className, variant, title, description, button }: AlertBannerProps) => {
  const Icon = iconDictionary[variant ?? "default"];
  return (
    <div className={cn(alertBannerVariants({ variant, className }))}>
      <div className={cn(alertIconVariant({ variant }))}>
        <Icon className="h-5 w-5 text-white" />
      </div>
      <div className="flex-1 space-y-2">
        {title && <div className="text-base font-bold leading-normal text-slate-800">{title}</div>}
        {description && (
          <div className="max-w-[800px] text-sm font-medium leading-normal text-gray-500">{description}</div>
        )}
      </div>
      <div className="flex items-center">
        {button?.label && button?.onClick && (
          <Button
            variant="outline"
            size="lg"
            //   className="text-red-500 border-2 border-red-500 hover:text-red-600"
            className={cn(alertBannerButtonVariant({ variant }))}
            onClick={button.onClick}
          >
            {button.label}
          </Button>
        )}
      </div>
    </div>
  );
};
